<template>
  <div>
    <div class="acc_box">
      <div v-if="payee_list.length > 0">
        <div
          class="acc_item"
          v-for="(item, index) in payee_list"
          :key="index"
          @click="selectAddr(item)"
        >
          <span style="pointer-events: none">
            <el-radio v-model="radio" :label="item.tx_id">
              {{ item.payee_addr }}
            </el-radio>
          </span>
          <div><i class="el-icon-edit" @click="to_edit(item)"></i></div>
        </div>
      </div>

      <div class="noData" v-else>{{ $t("main.text59") }}</div>

      <div v-if="!to_title" class="btm_btn" @click="$router.push('/add_addr')">
        {{ $t("main.text81") }}
      </div>
    </div>
  </div>
</template>

<script>
import { payee_list } from "../../../api/api";
export default {
  computed: {},
  data() {
    return {
      radio: "",
      to_title: this.$route.query.to_title,
      payee_list: [],
    };
  },
  mounted() {
    if (this.to_title) {
      sessionStorage.setItem("hichat_title", this.$t("main.text84"));
      this.bus.$emit("set_title", this.$t("main.text84"));
    }
    this.get_list();
    if (
      JSON.parse(sessionStorage.getItem("payee_addr")) &&
      JSON.parse(sessionStorage.getItem("payee_addr")).tx_id
    ) {
      this.radio = JSON.parse(sessionStorage.getItem("payee_addr")).tx_id;
    }
  },
  methods: {
    selectAddr(item) {
      this.radio = item.tx_id;
      let obj = {
        payee_addr: item.payee_addr,
        tx_id: item.tx_id,
      };
      sessionStorage.setItem("payee_addr", JSON.stringify(obj));
    },
    async get_list() {
      const res = await payee_list({ payee_type: 2 });
      if (res.data.code !== 200) {
        return this.msg("error", res.data.msg);
      }
      this.payee_list = res.data.data.list;
      if (this.radio == "" && res.data.data.list[0]) {
        this.radio = res.data.data.list[0].tx_id;
        sessionStorage.setItem(
          "payee_addr",
          JSON.stringify(res.data.data.list[0])
        );
      }
    },
    to_edit(item) {
      this.radio = item.tx_id;
      let obj = {
        payee_addr: item.payee_addr,
        tx_id: item.tx_id,
      };
      sessionStorage.setItem("payee_addr", JSON.stringify(obj));
      setTimeout(() => {
        this.$router.push({ path: "/add_addr", query: { is_edit: true } });
      }, 0);
    },
  },
};
</script>

<style scoped>
.acc_box {
  background: #f5f6f7;
  height: calc(100vh - 3rem - 2.75rem - 4rem);
  position: relative;
}
.btm_btn {
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  background: #2982d8;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
.acc_item {
  display: flex;
  justify-content: space-between;
  color: #353535;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 1.25rem;
  border-top: 0.125rem solid #f5f6f7;
  background: #fff;
}
.acc_item i {
  font-size: 1rem;
  vertical-align: middle;
}
.acc_box >>> .el-radio__input.is-checked + .el-radio__label {
  color: #353535;
}
.acc_box >>> .el-radio {
  font-weight: 400;
  color: #353535;
}
.noData {
  margin-top: 6rem;
}
</style> 

